import React, { ReactElement } from 'react';
import cx from 'classnames';

export interface LinkItemProps {
	title: string;
	url: string;
	cn?: string;
}
const LinkItem: React.FC<LinkItemProps> = ({ title, url, cn }): ReactElement => (
	<a href={url} className={cx('a-link u-heading-small', cn && `a-link-${cn}`)} target='_blank'>
		<p className={cx(cn && cn)}>{title}</p>
	</a>
);

export default LinkItem;
