import React, { ReactElement, useEffect } from 'react';
import Helmet from 'react-helmet';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Div100vh from 'react-div-100vh';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Lottie from 'lottie-react-web';

import Scroll from '../assets/images/svg/scroll.svg';

import Layout from '../components/Layout/Layout';
import LinkItem from '../components/LinkItem/LinkItem';

import { useAboutPageData, useSiteMetadata } from '../hooks';
import { initIntersectionObserver } from '../utils/intersectionObserver';
import { ILink } from '../types/index.types';
import { isNil, pathOr } from 'ramda';

const AboutPage = () => {
	const { contact, lottieAnimation, childContentfulPersonAboutRichTextNode } = useAboutPageData();
	const { name } = useSiteMetadata();
	const about = childContentfulPersonAboutRichTextNode.json;
	const animation = pathOr(null, ['internal', 'content'], lottieAnimation);

	useEffect(() => {
		initIntersectionObserver();
		return () => initIntersectionObserver();
	}, []);

	const renderLottieAnimation = (json: string): ReactElement => (
		<div className='p-about__animation'>
			<Lottie
				options={{
					animationData: JSON.parse(json),
					rendererSettings: {
						className: 'p-about__svg',
					}
				}}
			/>
		</div>
	);

	return (
		<Layout>
			<Helmet title={`About | ${name}`} />
			<section className='p-about'>
				<Div100vh>
					<header className='p-about__header'>
						<h2 className='u-hidden'>About</h2>
						{!isNil(animation) && renderLottieAnimation(animation)}
						<div className='p-about__scroll-wrapper'>
							<button
								className='p-about__scroll'
								onClick={() => scrollTo('#content')}
								title='Scroll to content'
							>
								<Scroll />
							</button>
						</div>
					</header>
				</Div100vh>

				<article className='p-about__content u-wrapper--content' id='content'>
					<div className='p-about__body'>
						<div data-inview data-animatein='default' >{documentToReactComponents(about)}</div>
					</div>

					<ul className='p-about__social' data-inview data-animatein='stagger'>
						{contact.map((item: ILink) => (
							<li key={item.id} className='p-about__social-item'>
								<LinkItem title={item.title} url={item.url} cn='stagger' />
							</li>
						))}
					</ul>
				</article>
			</section>
		</Layout>
	);
};

export default AboutPage;
